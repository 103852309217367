<template>
  <div class="bar">
    <Header class="hide" />
    <Headers class="dispy" />
    <div class="bar">
      <BannerImage rulename="dynamic_banner" />
      <div class="w">
        <Breadcrumb :crumblist="crumbdata" class="hide" />
        <div class="info">
          <div class="tb">
            <table border="1">
              <tr>
                <th class="trs" colspan="2" align="left">
                </th>
              </tr>
              <tr>
                <th><span>投诉对象</span></th>
                <td>
                  <p>{{ result.compSide }}</p>
                </td>
              </tr>
              <tr>
                <th><span>投诉内容</span></th>
                <td>
                  <p class="pre">{{ result.compReason }}</p>
                </td>
              </tr>
              <tr>
                <th><span>提交时间</span></th>
                <td>
                  <p>{{ result.createTime }}</p>
                </td>
              </tr>
              <tr>
                <th class="trs" colspan="2" align="left"></th>
              </tr>
              <tr>
                <th><span>反馈对象</span></th>
                <td>
                  <p>{{ result.createBy }}</p>
                </td>
              </tr>
              <tr>
                <th><span>反馈内容</span></th>
                <td>
                  <p class="pre">{{ result.compReply }}</p>
                </td>
              </tr>
              <tr>
                <th><span>反馈时间</span></th>
                <td>
                  <p>{{ result.updateTime }}</p>
                </td>
              </tr>
              <tr>
                <th class="trd" colspan="2" align="center">
                  <router-link to="/ComplaintList">
                    <button class="trd-but">返回</button>
                  </router-link>
                </th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import { getAction } from "@/api/manage";
import BannerImage from "@/components/main/BannerImage";
export default {
  components: {
    Header,
    Footer,
    Breadcrumb,
    BannerImage,
    Headers
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      crumbdata: [
        { name: "投诉与建议", url: "/ComplaintList" },
      ],
      result: {},
    };
  },
  methods: {
    loadData() {
      getAction("/complaint/tourComplaint/queryById", {
        id: this.$route.query.id,
      }).then((res) => {
        if (res.success) {
          this.result = res.result;
          this.crumbdata.push({
            name: res.result.theTitle,
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bar {
  .bars {
    width: 100%;
  }
  background: url(/img/white-bg.9771c1fc.png) no-repeat fixed top left;
}
.deti {
  padding: 20px 0 50px;
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: normal;
    color: #333333;
  }
  .biaoo {
    margin-top: 46px;
    margin-bottom: 30px;
    width: 1134px;
    background-color: #6EB40021;
    margin-left: 15px;
    .laiy {
      margin-left: 308px;
    }
    p {
      font-size: 14px;
      color: #999999;
      text-align: center;
      padding-bottom: 19px;
      padding-top: 19px;
    }
  }
  .desc {
    width: 1093px;
    margin: 0 auto;
    color: #666666;
    text-align: justify;
    min-height: 500px;
    p {
      img {
        display: block;
        width: 90% !important;
        margin: 0 auto;
      }
    }
  }
}
.info {
  background-color: #ffffff;
  padding-bottom: 22px;
  padding-top: 22px;
  margin-bottom: 47px;
}
.tb {
  margin-left: 22px;
  margin-right: 22px;
  ::v-deep.cell {
    text-align: center;
  }
}
td {
  padding: 26px;
  width: 989px;
}
table {
  border: solid 1px #dcdfe6;
}
.trs {
  background-color: #eff4ff;
  height: 48px;
  width: 112px;
  padding: 27px, 15px;
}
th {
  height: 48px;
  width: 112px;
  padding: 27px, 15px;
  img {
    padding-left: 22px;
  }
}
p {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: 1px;
  color: #666666;
}
span {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: 1px;
  color: #666666;
}
.trd-but {
  width: 68px;
  height: 30px;
  background-color: #eeeeee;
  border-radius: 3px;
  border: solid 1px #dcdfe6;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 1px;
  color: #ff7054;
  outline: 0;
}
.pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 20px;
}
</style>
