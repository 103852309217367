import { render, staticRenderFns } from "./Complaintdata.vue?vue&type=template&id=54f96da9&scoped=true&"
import script from "./Complaintdata.vue?vue&type=script&lang=js&"
export * from "./Complaintdata.vue?vue&type=script&lang=js&"
import style0 from "./Complaintdata.vue?vue&type=style&index=0&id=54f96da9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54f96da9",
  null
  
)

export default component.exports